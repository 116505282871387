import { createStore, applyMiddleware, combineReducers, compose, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

// core
import { accountReducer } from './account/reducers';
import { organisationReducer } from './organisation/reducers';
import { userReducer } from './user/reducers';
import { roleReducer } from './role/reducers';
import { employeeTypeReducer } from './employeeType/reducers';
import { notificationReducer } from './notifier/reducers';
import { groupReducer } from './group/reducers';
import { locationReducer } from './location/reducers';

// umo

import { dashboardReducer } from '../../_umo/lib/store/dashboard/reducers';
import { instructionReducer } from '../../_umo/lib/store/instruction/reducers';
import { myInstructionReducer } from '../../_umo/lib/store/myInstruction/reducers';
import { openInstructionReducer } from '../../_umo/lib/store/openInstruction/reducers';
import { instructionTypeReducer } from '../../_umo/lib/store/instructionType/reducers';
import { templateReducer } from '../../_umo/lib/store/template/reducers';
import { archiveReducer } from '../../_umo/lib/store/archive/reducers';
import { fileReducer } from './file/reducers';

export type { RootState, GetStateType, ProblemType } from './types';

export const reducers = {
  // core
  account: accountReducer,
  organisation: organisationReducer,
  user: userReducer,
  role: roleReducer,
  employeeType: employeeTypeReducer,
  location: locationReducer,
  group: groupReducer,
  notification: notificationReducer,
  file: fileReducer,

  // umo
  dashboard: dashboardReducer,
  instruction: instructionReducer,
  myInstruction: myInstructionReducer,
  openInstruction: openInstructionReducer,
  instructionType: instructionTypeReducer,
  template: templateReducer,
  archive: archiveReducer,
};

export const middleware = [thunkMiddleware];

// The store consists of one or more reducers and contains the app state
export const appReducer = combineReducers(reducers);

export const store: Store = createStore(
  appReducer,
  process.env.NODE_ENV === 'production'
    ? compose(applyMiddleware(...middleware))
    : composeWithDevTools(applyMiddleware(...middleware)),
);
