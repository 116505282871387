import {
  CreateInstructionType,
  UpdateInstructionType,
  AssignInstructionType,
  Instruction,
  InstructionStat,
  EmployeeStat,
  InstructionMetaResponse,
  PutInstructionTranslation,
  InstructionAsset,
} from '../types';
import { handleResponse, httpRequest, QueryObject } from '../../../../../lib/httpClient';
import { isObject } from '../../../../../lib/utils/helpers';

export const apiCreateInstruction = (body: CreateInstructionType): Promise<Instruction> =>
  httpRequest
    .post('/umo/instructions', { body })
    .then((res) => handleResponse<Instruction>(res))
    .then((data) => data)
    .catch();

export const apiUpdateInstruction = (_id: string, body: UpdateInstructionType): Promise<Instruction> =>
  httpRequest
    .put(`/umo/instructions/${_id}`, { body })
    .then((res) => handleResponse<Instruction>(res))
    .then((data) => data)
    .catch();

export const apiUpdateTranslation = (
  instructionId: string,
  body: { data: PutInstructionTranslation; assets: InstructionAsset[] },
): Promise<Instruction> =>
  httpRequest
    .put(`/umo/instructions/${instructionId}/translation`, { body })
    .then((res) => handleResponse<Instruction>(res))
    .then((data) => data)
    .catch();

export const apiAssignInstruction = (_id: string, body: AssignInstructionType): Promise<AssignInstructionType> =>
  httpRequest
    .put(`/umo/instructions/${_id}/assign`, { body })
    .then((res) => handleResponse<AssignInstructionType>(res))
    .then((data) => data)
    .catch();

export const apiGetInstruction = (_id: string, metaOnly?: boolean): Promise<Instruction> =>
  httpRequest
    .get(`/umo/instructions/${_id}${metaOnly ? '/meta' : ''}`)
    .then((res) => handleResponse<Instruction>(res))
    .then((data) => data)
    .catch();

export const apiGetInstructionAssignment = (_id: string): Promise<AssignInstructionType> =>
  httpRequest
    .get(`/umo/instructions/${_id}/assign`)
    .then((res) => handleResponse<AssignInstructionType>(res))
    .then((data) => data)
    .catch();

export const apiDeleteInstruction = (_id: string): Promise<unknown> =>
  httpRequest.delete(`/umo/instructions/${_id}`).then(handleResponse);

export const apiDeleteTranslation = (_id: string, instructionId: string): Promise<unknown> =>
  httpRequest.delete(`/umo/instructions/${instructionId}/translation/${_id}`).then(handleResponse);

export const apiGetInstructions = (filter?: QueryObject): Promise<InstructionMetaResponse> =>
  httpRequest
    .get(`/umo/instructions`, { query: filter || {} })
    .then((res) => handleResponse<InstructionMetaResponse>(res))
    .then((data) => data)
    .catch();

export const apiGetInstructionStats = (filter?: QueryObject): Promise<InstructionStat[]> =>
  httpRequest
    .get(`/umo/instructions/stats`, { query: filter || {} })
    .then((res) => handleResponse<InstructionStat[]>(res))
    .then((data) => (isObject(data) ? [] : data))
    .catch();

export const apiGetEmployeeStats = (filter?: QueryObject): Promise<EmployeeStat[]> =>
  httpRequest
    .get(`/umo/instructions/user-stats`, { query: filter || {} })
    .then((res) => handleResponse<EmployeeStat[]>(res))
    .then((data) => (isObject(data) ? [] : data))
    .catch();
