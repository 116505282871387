/* eslint-disable no-console */
import React, { ReactElement, useRef, useState } from 'react';
import { Grid, Box, Paper, IconButton } from '@material-ui/core';
import { Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { ArrowForwardRounded, ArrowBackRounded } from '@material-ui/icons';
import clsx from 'clsx';
import { toJpeg } from '../../../lib/htmlToImage';

import { InfoBox } from './components/InfoBox';
import { InstructionViewerDispatchProvider } from '../../lib/context/instructionViewer';
import { Questionnaire } from './components/Questionnaire';
import { ViewerProps, InitialQuestionnaireValues } from './types';
import { useInstructionViewer } from '../../lib/context/instructionViewer/useInstructionViewer';
import { actionTypes } from '../../lib/context/instructionViewer/constants';
import { PageNavigation } from './components/PageNavigation';
import { EvaluationScreen } from './components/EvaluationScreen';

import { useInstructionViewerStyles } from './styles';

import { scrollToTarget } from './utils';
import { HEADER_HEIGHT } from '../../../components/templates/foundation/BaseTemplate';
import { useImageCallIds } from './useImageCallIds';

export const InstructionViewer = (props: ViewerProps): ReactElement => {
  const {
    initialSlides,
    instructionName,
    instructionType,
    createdBy,
    nextDue,
    evaluated,
    previewMode,
    isEvaluating,
    handleEvaluateResults,
    handleReset,
    shouldAutoReset,
    viewToken,
    hideHeader,
    isWizardMode,
    isExample,
  } = props;
  const classes = useInstructionViewerStyles({ isWizardMode });
  const {
    dispatch,
    getState,
    activeSlideIndex,
    activeSlideJson,
    activeSlideQuestionnaire,
    questionnaireInitialValues,
    activeSlideType,
    slides,
    results: resultsInitial,
    header,
  } = useInstructionViewer({
    initialSlides,
  });

  const query = new URLSearchParams(window.location.search);
  const mode = query.get('mode');

  const containerRef = useRef<HTMLDivElement>(null);

  const [captures, setCaptures] = useState<string[]>([]);
  const [isCapturing, setIsCapturing] = useState<'' | 'next' | 'prev'>('');

  // should not capture if previewmode, or if no questionnaire
  const shouldCaptureSlides = !previewMode && resultsInitial.length > 0;

  const questionnaireFormRef = useRef<FormikProps<InitialQuestionnaireValues>>(null);

  const { t } = useTranslation();

  const replaceResults = (): void => {
    if (activeSlideType === 'questionnaire' && questionnaireFormRef && questionnaireFormRef.current) {
      const {
        values: { results },
      } = questionnaireFormRef.current;
      dispatch({
        type: actionTypes.REPLACE_RESULT,
        payload: {
          slideId: slides[activeSlideIndex]._id,
          sets: results.map((result, index) => ({ setIndex: index, givenAnswers: result.givenAnswers })),
        },
      });
    }
  };

  const createSlideCapture = async (slideIndex: number, direction: 'prev' | 'next') => {
    const node = document.getElementById('ds-iv-capture');

    if (node) {
      setIsCapturing(direction);
      return toJpeg(node, { quality: 0.7, fetchRequestInit: { credentials: 'include' } })
        .then((dataUrl) => {
          // if first, set initial
          if (captures.length === 0) return [dataUrl];
          // if passed index is larger than items, add it to the array
          if (slideIndex > captures.length - 1) return [...captures, dataUrl];

          // else, replace item
          return captures.map((capture, index) => {
            return index === slideIndex ? dataUrl : capture;
          });
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error({
            type: 'error',
            code: 'IV_CAPTURE_SLIDE',
            message: 'Error when capturing slide',
            stack: JSON.stringify(error),
          });
          return captures;
        })
        .finally(() => {
          setIsCapturing('');
        });
    }

    // eslint-disable-next-line no-console
    console.error({
      type: 'warning',
      code: 'IV_CAPTURE_SLIDE',
      message: 'Error when capturing slide. Node not found',
    });

    return captures;
  };

  const handleNextClick = async (): Promise<void> => {
    replaceResults();

    if (shouldCaptureSlides) {
      const data = await createSlideCapture(activeSlideIndex, 'next');

      setCaptures(data);
    }

    dispatch({
      type: actionTypes.CHANGE_ACTIVE_SLIDE_INDEX,
      payload: activeSlideIndex + 1,
    });

    scrollToTarget(containerRef, parseInt(HEADER_HEIGHT, 10));
  };

  const handlePrevClick = async (): Promise<void> => {
    replaceResults();
    if (shouldCaptureSlides) {
      const data = await createSlideCapture(activeSlideIndex, 'prev');

      setCaptures(data);
    }
    dispatch({
      type: actionTypes.CHANGE_ACTIVE_SLIDE_INDEX,
      payload: activeSlideIndex - 1,
    });

    scrollToTarget(containerRef, parseInt(HEADER_HEIGHT, 10));
  };

  const handleSelectPage = async (page: number): Promise<void> => {
    replaceResults();

    if (shouldCaptureSlides) {
      const data = await createSlideCapture(activeSlideIndex, 'next');

      setCaptures(data);
    }

    dispatch({
      type: actionTypes.CHANGE_ACTIVE_SLIDE_INDEX,
      payload: page,
    });
  };

  const evaluateResult = async (): Promise<void> => {
    replaceResults();
    let data: string[] = [];
    if (shouldCaptureSlides) {
      data = await createSlideCapture(activeSlideIndex, 'next');
    }

    const { results } = getState();
    if (handleEvaluateResults) {
      handleEvaluateResults(results, data);
    }
  };

  const reset = () => {
    dispatch({
      type: actionTypes.RESET_RESULT,
    });
    handleReset();
    handleSelectPage(0);
  };

  const data = activeSlideJson && JSON.parse(activeSlideJson);
  const headerData = !hideHeader && header && JSON.parse(header);

  const { imageCallIds, autoRelease } = useImageCallIds(activeSlideIndex, '#ds-iv-capture img', viewToken);

  // check if images are loading if it should capture slides at all
  const isLoadingImages = !autoRelease && !!(shouldCaptureSlides && imageCallIds.filter(Boolean).length > 0);

  return (
    <InstructionViewerDispatchProvider value={dispatch}>
      <div
        ref={containerRef}
        className={clsx(isWizardMode && classes.overflowHidden, mode === 'embed' && classes.embedded)}
      >
        <div key={activeSlideIndex} className={clsx(isWizardMode && classes.fHeight)}>
          <Paper
            square
            elevation={isWizardMode ? 0 : undefined}
            className={clsx(classes.contentWrap, isWizardMode && classes.scroll)}
          >
            {evaluated && nextDue ? (
              <EvaluationScreen nextDue={nextDue} evaluated={evaluated} shouldAutoReset={shouldAutoReset} />
            ) : activeSlideType === 'questionnaire' && activeSlideQuestionnaire && questionnaireInitialValues ? (
              <Grid container>
                <Grid item xs={12}>
                  {!isExample && headerData?.css && <style>{headerData.css}</style>}
                  <Box
                    display="flex"
                    flexDirection="column"
                    flex="1"
                    id="ds-iv-capture"
                    className={classes.captureContainer}
                  >
                    {!isExample && headerData?.html && (
                      <Box>
                        {/* eslint-disable-next-line react/no-danger */}
                        <div dangerouslySetInnerHTML={{ __html: headerData.html }} />
                      </Box>
                    )}
                    <Formik
                      onSubmit={(): void => undefined}
                      initialValues={questionnaireInitialValues}
                      innerRef={questionnaireFormRef}
                    >
                      {(formikProps): ReactElement => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <Questionnaire questionnaire={activeSlideQuestionnaire} {...formikProps} />
                      )}
                    </Formik>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid item xs={12}>
                  <style>{data.css}</style>
                  {!isExample && headerData?.css && <style>{headerData.css}</style>}
                  <Box
                    display="flex"
                    flexDirection="column"
                    flex="1"
                    id="ds-iv-capture"
                    className={classes.captureContainer}
                    position="relative"
                  >
                    {!isExample && headerData?.html && (
                      <Box>
                        {/* eslint-disable-next-line react/no-danger */}
                        <div dangerouslySetInnerHTML={{ __html: headerData.html }} />
                      </Box>
                    )}

                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={{ __html: data.html }} />
                  </Box>
                </Grid>
              </Grid>
            )}
          </Paper>

          <InfoBox
            activeSlideIndex={activeSlideIndex}
            totalSlides={slides.length}
            instructionName={instructionName}
            instructionType={instructionType}
            createdBy={createdBy}
            isWizardMode={isWizardMode}
          >
            {isWizardMode ? (
              <Box className={classes.simpleNav}>
                {slides.length > 1 && (
                  <>
                    <IconButton onClick={handlePrevClick}>
                      <ArrowBackRounded />
                    </IconButton>
                    <IconButton onClick={handleNextClick}>
                      <ArrowForwardRounded />
                    </IconButton>
                  </>
                )}
              </Box>
            ) : (
              <PageNavigation
                previewMode={!!previewMode}
                evaluated={evaluated}
                canEvaluate={shouldCaptureSlides}
                isEvaluating={isEvaluating}
                isCapturing={isCapturing}
                disabledNext={isLoadingImages}
                activeSlideIndex={activeSlideIndex}
                totalSlides={slides.length}
                handleNextClick={handleNextClick}
                handlePrevClick={handlePrevClick}
                handleSelectPage={handleSelectPage}
                handleEvaluate={evaluateResult}
                handleReset={reset}
                helperText={isLoadingImages ? t('shared.instructionViewer.isLoadingImages') : undefined}
              />
            )}
          </InfoBox>
        </div>
      </div>
    </InstructionViewerDispatchProvider>
  );
};
