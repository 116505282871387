export enum AvailablePermissions {
  CoreCrosssystemSettingsFind = 'core_crossSystem_settings_find',
  CoreCrosssystemSettingsUpdate = 'core_crossSystem_settings_update',
  CoreCrosssystemSettingsBillingFind = 'core_crossSystem_settings_billing_find',
  CoreCrosssystemSettingsBillingUpdate = 'core_crossSystem_settings_billing_update',
  CoreCrosssystemSettingsModulesBuy = 'core_crossSystem_settings_modules_buy',
  CoreCrosssystemSettingsModulesDeactivate = 'core_crossSystem_settings_modules_deactivate',
  CoreUseradministrationFind = 'core_userAdministration_find',
  CoreUseradministrationCreate = 'core_userAdministration_create',
  CoreUseradministrationUpdate = 'core_userAdministration_update',
  CoreUseradministrationDelete = 'core_userAdministration_delete',
  CoreRoleFind = 'core_role_find',
  CoreRoleCreate = 'core_role_create',
  CoreRoleUpdate = 'core_role_update',
  CoreRoleDelete = 'core_role_delete',
  CoreEmployeetypesFind = 'core_employeeTypes_find',
  CoreEmployeetypesCreate = 'core_employeeTypes_create',
  CoreEmployeetypesUpdate = 'core_employeeTypes_update',
  CoreEmployeetypesDelete = 'core_employeeTypes_delete',
  CoreCompanystructureFind = 'core_companyStructure_find',
  CoreCompanystructureCreate = 'core_companyStructure_create',
  CoreCompanystructureUpdate = 'core_companyStructure_update',
  CoreCompanystructureDelete = 'core_companyStructure_delete',
  CoreGroupadministrationFind = 'core_groupAdministration_find',
  CoreGroupadministrationCreate = 'core_groupAdministration_create',
  CoreGroupadministrationUpdate = 'core_groupAdministration_update',
  CoreGroupadministrationDelete = 'core_groupAdministration_delete',
  CoreActivemodulesselectAll = 'core_activeModulesSelect_all',
  CoreUsersettingsViewsettingsFind = 'core_userSettings_viewSettings_find',
  CoreUsersettingsViewsettingsPasswordUpdate = 'core_userSettings_viewSettings_password_update',

  CoreFilesFind = 'core_files_find',
  CoreFilesCreate = 'core_files_create',
  CoreFilesDelete = 'core_files_delete',

  UmoDashboardStatisticsFind = 'umo_dashboard_statistics_find',

  // Uebersicht der Unterweisungen, anzeige, erstellen, aendern, loeschen, vorschau, uebersetzen
  UmoInstructionadministrationListFind = 'umo_instructionAdministration_list_find',
  UmoInstructionadministrationCreate = 'umo_instructionAdministration_create',
  UmoInstructionadministrationUpdate = 'umo_instructionAdministration_update',
  UmoInstructionadministrationDelete = 'umo_instructionAdministration_delete',
  UmoInstructionadministrationPreview = 'umo_instructionAdministration_preview',
  UmoInstructionadministrationTranslate = 'umo_instructionAdministration_translate',

  // Unterweisungen zuordnen via, unternehmensstruktur, group, mitarbeitertyp, direkten usern
  UmoInstructionassignmentCompanystructure = 'umo_instructionAssignment_companyStructure',
  UmoInstructionassignmentGroup = 'umo_instructionAssignment_group',
  UmoInstructionassignmentEmployeeType = 'umo_instructionAssignment_employeeType',
  UmoInstructionassignmentUser = 'umo_instructionAssignment_user',
  UmoInstructionassignmentPublic = 'umo_instructionAssignment_public',

  // eigene unterweisungen anzeigen
  UmoInstructionexecutionListFind = 'umo_instructionExecution_list_find',
  // unterweisung execute / durchfuehren
  UmoInstructionexecutionExecute = 'umo_instructionExecution_execute',
  // instruction ansehen
  UmoInstructionexecutionFind = 'umo_instructionExecution_find',

  // vorlagen anzeigen, erstellen, aendern, loeschen, uebersetzen
  UmoTemplatesListFind = 'umo_templates_list_find',
  UmoTemplatesCreate = 'umo_templates_create',
  UmoTemplatesUpdate = 'umo_templates_update',
  UmoTemplatesDelete = 'umo_templates_delete',
  UmoTemplatesTranslate = 'umo_templates_translate',

  // Termin Kontrolle -> unterweisungsansicht und mitarbeiteransicht (detail ansicht)
  UmoInstructioncontrolInstructionviewFind = 'umo_instructionControl_instructionView_find',
  UmoInstructioncontrolEmployeeviewFind = 'umo_instructionControl_employeeView_find',
  UmoReportUpcomingInstructionFind = 'umo_report_upcoming_instructions_get',

  UmoInstructionarchiveListFind = 'umo_instructionArchive_list_find',
  UmoInstructionarchiveExport = 'umo_instructionArchive_export',
  UmoInstructionarchiveDelete = 'umo_instructionArchive_delete',

  // Unterweisungetypen
  // TODO: uebersetzen
  UmoInstructiontypesFind = 'umo_instructionTypes_find',
  UmoInstructiontypesCreate = 'umo_instructionTypes_create',
  UmoInstructiontypesUpdate = 'umo_instructionTypes_update',
  UmoInstructiontypesDelete = 'umo_instructionTypes_delete',

  UmoSettingsFind = 'umo_settings_find',
  UmoSettingsUpdate = 'umo_settings_update',
}
