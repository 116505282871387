import React, { ReactElement, ReactNode } from 'react';

import { Box, Typography, List, ListItem, Divider } from '@material-ui/core';

import { CategoryPickerProps } from './types';
import { useCategoryPickerStyles } from './styles';

export const CategoryPicker = (props: CategoryPickerProps): ReactElement => {
  const { onNodeSelect, selected, categories } = props;

  const classes = useCategoryPickerStyles();

  const items: ReactNode[] = [];
  let currentItems: ReactNode[] = [];
  let currentTitle = categories[0].title;

  categories.forEach((category, index) => {
    const { name, title, value, divider } = category;
    const end = index === categories.length - 1;

    const isActive = selected === value;

    if (title !== currentTitle || end) {
      if (end) {
        currentItems.push(
          <>
            {divider && (
              <Box mt={2} mb={2}>
                <Divider />
              </Box>
            )}
            <ListItem
              button
              dense
              disableGutters
              className={`${classes.category} ${isActive ? classes.activeListItem : ''}`}
              key={value}
              onClick={() => onNodeSelect(value)}
            >
              <Typography
                variant="body2"
                className={`${classes.listItemText} ${isActive ? classes.activeListItemText : ''}`}
              >
                {name}
              </Typography>
            </ListItem>
          </>,
        );
      }

      items.push(
        <List
          subheader={currentTitle ? <Typography variant="h5">{currentTitle}</Typography> : undefined}
          key={`${currentTitle}-root`}
        >
          {currentItems}
        </List>,
      );
      currentTitle = title;
      currentItems = [];
    }

    currentItems.push(
      <ListItem
        button
        dense
        disableGutters
        className={`${classes.category} ${isActive ? classes.activeListItem : ''}`}
        key={value}
        onClick={() => onNodeSelect(value)}
      >
        <Typography variant="body2" className={`${classes.listItemText} ${isActive ? classes.activeListItemText : ''}`}>
          {name}
        </Typography>
      </ListItem>,
    );
  });

  return <Box>{items}</Box>;
};
