import React, { ReactElement, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { SearchRounded, ImageRounded } from '@material-ui/icons';

import { Box, Grid } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { attemptGetFiles } from '../../lib/store/file/effects';
import { RootState } from '../../lib/store';

import { SingleFieldForm } from '../../components/organisms/form/SingleFieldForm';

import { TextWithIconButton } from '../../components/molecules/button';
import { FileCard } from './components/FileCard';

import { PlaceholderBox } from '../../components/molecules/box/PlaceholderBox';
import { FileUploadCard } from './components/FileUploadCard';
import { setFileUploadList } from '../../lib/store/file/actions';
import { isFileLoadingState, FileType, FileStatic } from '../../lib/store/file/types';

import { FileStaticCard } from './components/FileStaticCard';
import { isFileType } from '../../lib/utils/typeguards';
import { CategoryPicker } from '../../components/organisms/menu/CategoryPicker';
import { hasAccountRequiredPermission } from '../../lib/utils';
import { AvailablePermissions } from '../../lib/constants/availablePermissions';

export type FileLibraryProps = {
  handleSelect?: (file: FileType | FileStatic) => void;
  initialGroup?: string;
};

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as const,
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  outline: 'none',
  transition: 'border-color .24s ease-in-out',
  position: 'absolute' as const,
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#111',
  backgroundColor: '#fcfcfc',
  color: '#bdbdbd',
  zIndex: 20,
  borderWidth: 2,
  borderRadius: 2,
  borderStyle: 'dashed',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export const FileLibrary = (props: FileLibraryProps): ReactElement => {
  const { handleSelect, initialGroup } = props;

  const reduxDispatch = useDispatch();
  const { t } = useTranslation();
  const {
    file: { fileList, loading },
    organisation: { current: currentOrganisation },
    account: { current: currentAccount },
  } = useSelector((state: RootState) => state);

  const accountPermissions = (currentAccount && currentAccount.role.permissions) || [];
  const hasFileCreatePermission = hasAccountRequiredPermission(accountPermissions, [
    AvailablePermissions.CoreFilesCreate,
  ]);
  const hasFileDeletePermission = hasAccountRequiredPermission(accountPermissions, [
    AvailablePermissions.CoreFilesDelete,
  ]);

  const showExamples = currentOrganisation?._id === '62265aed27bac500134848f4';

  const [selectedGroup, setSelectedGroup] = React.useState<string>(initialGroup || '');

  const isLoading = loading === 'fileGet';

  useEffect(() => {
    reduxDispatch(
      attemptGetFiles({
        sort: 'desc',
        category: selectedGroup ? 'static' : 'images',
        group: selectedGroup || undefined,
      }),
    );
  }, [selectedGroup]);

  const onDrop = React.useCallback(async (acceptedFiles: File[]) => {
    reduxDispatch(setFileUploadList(acceptedFiles.map((file) => ({ file, tempId: uuidv4() }))));
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: ['image/png', 'image/jpeg', 'image/gif'],
    onDrop,
    noClick: true,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  const handleSearch = (value: string) => {
    const query = {
      sort: 'desc',
      category: selectedGroup ? 'static' : 'images',
      group: selectedGroup || undefined,
      originalName: value,
    };

    reduxDispatch(attemptGetFiles(query));
  };

  const handleSelectGroup = (value: string) => {
    setSelectedGroup(value);
  };

  return (
    <>
      {!selectedGroup && hasFileCreatePermission && (
        <section className="container">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <div {...getRootProps({ style, className: 'dropzone' })}>
            {isDragAccept && <p>{t('module-core:shared.common.dragNDrop')}</p>}
          </div>
        </section>
      )}

      <Grid container spacing={2}>
        <Grid item xs={2}>
          <CategoryPicker
            categories={[
              { title: '', name: t('module-core:shared.common.userDefined'), value: '' },
              {
                title: t('module-core:shared.common.icons'),
                name: t('module-core:shared.common.office'),
                value: 'signs/office-signs',
              },
              {
                title: t('module-core:shared.common.icons'),
                name: t('module-core:shared.common.gastronomy'),
                value: 'signs/gastronomy-signs',
              },

              {
                title: t('module-core:shared.common.icons'),
                name: t('module-core:shared.common.craft'),
                value: 'signs/craft-signs',
              },
              {
                title: t('module-core:shared.common.icons'),
                name: t('module-core:shared.common.hygiene'),
                value: 'signs/hygiene-signs',
              },
              {
                title: t('module-core:shared.common.icons'),
                name: t('module-core:shared.common.transport'),
                value: 'signs/transport-signs',
              },
              {
                title: t('module-core:shared.common.icons'),
                name: t('module-core:shared.common.other'),
                value: 'signs/other-signs',
              },
              {
                title: t('module-core:shared.common.icons'),
                name: t('module-core:shared.common.caricatures'),
                value: 'signs/caricatures-signs',
              },
              {
                title: t('module-core:shared.common.icons'),
                name: t('module-core:shared.common.imagesSigns'),
                value: 'signs/images-signs',
              },
              {
                title: t('module-core:shared.common.signs'),
                name: t('module-core:shared.common.commandmentSigns'),
                value: 'signs/commandment-signs',
              },
              {
                title: t('module-core:shared.common.signs'),
                name: t('module-core:shared.common.fireProtectionSigns'),
                value: 'signs/fire-protection-signs',
              },
              {
                title: t('module-core:shared.common.signs'),
                name: t('module-core:shared.common.prohibitionSigns'),
                value: 'signs/prohibition-signs',
              },
              {
                title: t('module-core:shared.common.signs'),
                name: t('module-core:shared.common.rescueSigns'),
                value: 'signs/rescue-signs',
              },
              {
                title: t('module-core:shared.common.signs'),
                name: t('module-core:shared.common.warningSigns'),
                value: 'signs/warning-signs',
              },
              ...(showExamples
                ? [
                    {
                      title: t('module-core:shared.common.signs'),
                      name: t('module-core:shared.common.examples'),
                      value: 'examples/examples',
                    },
                  ]
                : []),
            ]}
            selected={selectedGroup}
            onNodeSelect={handleSelectGroup}
          />
        </Grid>

        <Grid item xs={10}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <SingleFieldForm
                    loading={loading === 'fileGet'}
                    fieldLabel={t('module-core:shared.common.searchFile')}
                    fieldName="search"
                    handleSubmit={handleSearch}
                    isRequired={false}
                    resetOnSubmit={false}
                    icon={<SearchRounded />}
                    iconColor="primary"
                  />
                </Grid>
                {!selectedGroup && hasFileCreatePermission && (
                  <Grid item xs={6}>
                    <Box display="flex" height="100%" justifyContent="flex-end">
                      <Box alignSelf="flex-end">
                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                        <TextWithIconButton component={'label' as any} endIcon={<ImageRounded />}>
                          {t('module-core:shared.common.upload')}
                          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                          <input {...getInputProps()} />
                        </TextWithIconButton>
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {!isLoading && fileList.length === 0 ? (
                  <Grid item xs={12}>
                    <PlaceholderBox helpText={t('module-core:shared.common.noFiles')} />
                  </Grid>
                ) : isLoading ? (
                  [...Array(3)].map((_, index) => {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <Grid key={index} item xs={12} sm={4} md={3}>
                        <Box>
                          <Skeleton variant="rect" height="200px" />
                        </Box>
                        <Box pb={4}>
                          <Skeleton />
                          <Skeleton width="60%" />
                          <Skeleton width="60%" />
                        </Box>
                      </Grid>
                    );
                  })
                ) : (
                  fileList.map((data) => {
                    if (isFileLoadingState(data)) {
                      return (
                        <Grid item xs={12} sm={4} md={3} key={data.tempId}>
                          <FileUploadCard fileUpload={data.file} tempId={data.tempId} />
                        </Grid>
                      );
                    }

                    const { file, status } = data;

                    if (isFileType(file)) {
                      return (
                        <Grid key={file._id} item xs={12} sm={4} md={3}>
                          <FileCard
                            _id={file._id}
                            fileName={file.fileName}
                            originalName={file.originalName}
                            createdAt={file.createdAt}
                            createdBy={file.createdBy}
                            src={file.thumbnail?.proxyUrl || ''}
                            status={status}
                            usedBy={file.usedBy}
                            usedByHeader={file.usedByHeader}
                            handleSelect={handleSelect ? () => handleSelect(file) : undefined}
                            hasFileDeletePermission={hasFileDeletePermission}
                          />
                        </Grid>
                      );
                    }

                    return (
                      <Grid key={file.location} item xs={12} sm={4} md={3}>
                        <FileStaticCard
                          fileName={file.fileName}
                          location={file.location}
                          handleSelect={handleSelect ? () => handleSelect(file) : undefined}
                        />
                      </Grid>
                    );
                  })
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
