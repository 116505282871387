import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { SECONDARY_MAIN_COLOR } from '../../../../lib/theme/constants';

export const useCategoryPickerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 216,
      flexGrow: 1,
      maxWidth: 400,
    },
    category: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      cursor: 'pointer',
    },
    activeListItem: {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      '&:after': {
        content: '""',
        width: '10px',
        height: '10px',
        background: SECONDARY_MAIN_COLOR,
        position: 'absolute',
        borderRadius: '100%',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '0',
      },
    },
    activeListItemText: {
      fontWeight: 500,
    },
    listItemText: {
      paddingRight: theme.spacing(1.5),
    },
  }),
);
