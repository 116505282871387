import React, { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { BaseDialog } from '../../components/molecules/window/BaseDialog';
import { FileLibrary } from './FileLibrary';
import { FileType, FileStatic } from '../../lib/store/file/types';
import { isFileType } from '../../lib/utils/typeguards';
import { getStaticFileUrl } from './utils';

export type FileLibraryProps = {
  onClose: () => void;
  open: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editorProps?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect?: (url: string, editorProps?: any) => void;
  onAssetChange: (type: 'add' | 'delete', payload: { viewId: string; asset: string }) => void;
};
export const FileLibraryDialog = (props: FileLibraryProps): ReactElement => {
  const { open, onClose, editorProps, onAssetChange, onSelect } = props;
  const { t } = useTranslation();

  const handleClose = () => {
    // tell editor the asset manager is closed if given
    if (editorProps && typeof editorProps.close === 'function') {
      editorProps.close();
    }

    onClose();
  };

  const handleSelect = (file: FileType | FileStatic) => {
    if (isFileType(file)) {
      if (editorProps && typeof editorProps.select === 'function') {
        const { fileId, id } = editorProps?.options?.target?.attributes?.attributes || {};
        // if fileId exists, it means we are editing an asset
        if (fileId) {
          onAssetChange('delete', { viewId: id, asset: fileId });
        }

        // set ref and select file
        onAssetChange('add', { viewId: id, asset: file._id });
        editorProps.select({ fileId: file._id, src: file.proxyUrl });
      }

      if (onSelect) {
        onSelect(file.proxyUrl, editorProps);
      }
    } else {
      const src = getStaticFileUrl(file);

      if (onSelect) {
        onSelect(src, editorProps);
      }

      editorProps?.select({ src });
    }

    handleClose();
  };

  return (
    <BaseDialog
      DialogProps={{ maxWidth: 'lg', fullWidth: true }}
      open={open}
      onClose={handleClose}
      title={t('module-core:shared.files.title')}
    >
      <FileLibrary handleSelect={handleSelect} />
    </BaseDialog>
  );
};
